import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="debating">
    <div className="app__specialMenu-title">
      <SubHeading title="Classic Debate" />
      <h1 className="headtext__cormorant">British Parliamentary (BP) Debate</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Government</p>
        <div className="app__specialMenu_menu_items">
          {data.government.map((government, index) => (
            <MenuItem key={government.title + index} title={government.title} price={government.price} tags={government.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.justicebg} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Opposition</p>
        <div className="app__specialMenu_menu_items">
          {data.opposition.map((opposition, index) => (
            <MenuItem key={opposition.title + index} title={opposition.title} price={opposition.price} tags={opposition.tags} />
          ))}
        </div>
      </div>
    </div>

    <div style={{ marginTop: 15 }}>
      <button type="button" className="custom__button">View More</button>
    </div>
  </div>
);

export default SpecialMenu;

