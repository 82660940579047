import React from 'react';

import { GiHamburgerMenu } from 'react-icons/gi';
import { GiAfrica } from 'react-icons/gi';
import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logoWhite} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="#home">Home</a></li>
        <li className="p__opensans"><a href="#about">About</a></li>
        <li className="p__opensans"><a href="#debating">Debating</a></li>
        <li className="p__opensans"><a href="https://paudc.olympiadebate.org">PAUDC 2024</a></li>
        <li className="p__opensans"><a href="#wudc">WUDC 2026 Bid</a></li>
      </ul>
      {/* <div className="app__navbar-login">
        <a href="/ecommerce" className="p__opensans">Olympia e-shop</a>
        <div />
        <a href="login" className="p__opensans">Donate</a>
      </div> */}
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <GiAfrica fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
              <li><a href="#about" onClick={() => setToggleMenu(false)}>About</a></li>
              <li><a href="#debating" onClick={() => setToggleMenu(false)}>Debating</a></li>
              <li className="p__opensans"><a href="https://paudc.olympiadebate.org">PAUDC 2024</a></li> {/* Update this line */}
              <li><a href="#wudc" onClick={() => setToggleMenu(false)}>WUDC 2026 Bid</a></li>
              {/* <li><a href="/ecommerce" onClick={() => setToggleMenu(false)}>Olympia e-shop</a></li>
              <li><a href="#login" onClick={() => setToggleMenu(false)}>Donate</a></li> */}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;