import React from 'react';

import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Intro, Laurels, SpecialMenu } from './container';
import { Navbar } from './components';
import './App.css';
// import Hero from './container/Hero/Hero';
import PhotoFlipperContainer from './container/PhotoFlipperContainer/PhotoFlipperContainer';
import Wudc from './container/Wudc/Wudc';
// import DemoBook from './container/DemoBook/DemoBook';
// import Book from './container/Book/Book';
import Report from './container/Report/Report';
import Budget from './container/Budget/Budget';

const App = () => (
  <div>
    <Navbar />
    <Intro />
    <Header/>
    <AboutUs />
    <SpecialMenu />
    <Chef />
    <Report />
    <Budget />
    <Laurels />
    <Wudc />
    <PhotoFlipperContainer />
    {/* <Book />
    <DemoBook/> */}
    
    <Gallery />
    <FindUs />
    <Footer />
    
  </div>
);

export default App;
