import React from "react";
import "./Wudc.css";

import { images } from '../../constants';

import PhotoFlipper from "../../components/PhotoFlipper/PhotoFlipper";

const imagesQ = [

images.wudc01,
images.wudc02,
images.wudc03,
images.wudc04,
images.wudc05,
images.wudc06,
images.wudc07,
images.wudc08,
images.wudc09,
images.wudc10,
images.wudc11,
images.wudc12,
images.wudc13,
images.wudc14,
images.wudc15,
images.wudc16,
images.wudc17,
images.wudc18,
images.wudc19,
images.wudc20,
images.wudc21,
images.wudc22,
images.wudc23,
images.wudc24,
images.wudc25,
images.wudc26,
images.wudc27,
images.wudc28,
images.wudc29,
images.wudc30,
images.wudc31,
images.wudc32,
images.wudc33,
images.wudc34,
images.wudc35,
images.wudc36,
images.wudc37,
images.wudc38,
images.wudc39,
images.wudc40,
images.wudc41,
images.wudc42,
images.wudc43,
images.wudc44,
images.wudc45,
images.wudc46,
images.wudc47,
images.wudc48,
images.wudc49,
images.wudc50,
images.wudc51,
images.wudc52,
images.wudc53,
images.wudc54,
images.wudc55,
images.wudc56,
images.wudc57,
images.wudc58,
images.wudc59,
images.wudc60,
images.wudc61,
images.wudc62,
images.wudc63,
images.wudc64,
images.wudc65,
images.wudc66,
images.wudc67,

];


const PhotoFlipperContainer = () => {
  return (
    <div className="photo-flipper-container" id="wudc">
      <h3 className="headtext__cormorant">WUDC 2026 Bid</h3>
      <PhotoFlipper images={imagesQ} />
    </div>
  );
};

export default PhotoFlipperContainer;