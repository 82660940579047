import bg from '../assets/bg.png';
import debateGroup1 from '../assets/debateGroup1.png';
import debateGroup2 from '../assets/debateGroup2.png';
import debateGroup3 from '../assets/debateGroup3.png';
import debateGroup4 from '../assets/debateGroup4.png';
import debateGroup5 from '../assets/debateGroup5.png';
import debateGroup6 from '../assets/debateGroup6.png';
import debater1 from '../assets/debater1.png';
import debater2 from '../assets/debater2.png';
import debater3 from '../assets/debater3.png';
import debater4 from '../assets/debater4.png';
import debater5 from '../assets/debater5.png';
import debater6 from '../assets/debater6.png';
import debater7 from '../assets/debater7.png';
import debater8 from '../assets/debater8.png';
import debater9 from '../assets/debater9.png';
import debater10 from '../assets/debater10.png';
import debater11 from '../assets/debater11.png';
import logoBlack from '../assets/logoBlack.png';
import logoWhite from '../assets/logoWhite.png';
import ribbon from '../assets/ribbon.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import olympiaLogo from '../assets/olympiaLogo.png';
import kyambogo from '../assets/kyambogo.png';
import quote from '../assets/quote.png';
import sign from '../assets/sign.png';
import meal from '../assets/meal.mp4';
import justicebg from '../assets/justicebg.png';
import gold from '../assets/gold.png';
import bg1 from '../assets/bg1.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award04 from '../assets/award04.png';
import shabil from '../assets/shabil.png';
import ugandaDances from '../assets/ugandaDances.png';
import shedule1 from '../assets/shedule1.png';
import shedule2 from '../assets/shedule2.png';
import shedule3 from '../assets/shedule3.png';
import wudc01 from '../assets/wudc01.jpg' ;
import wudc02 from '../assets/wudc02.jpg' ;
import wudc03 from '../assets/wudc03.jpg' ;
import wudc04 from '../assets/wudc04.jpg' ;
import wudc05 from '../assets/wudc05.jpg' ;
import wudc06 from '../assets/wudc06.jpg' ;
import wudc07 from '../assets/wudc07.jpg' ;
import wudc08 from '../assets/wudc08.jpg' ;
import wudc09 from '../assets/wudc09.jpg' ;
import wudc10 from '../assets/wudc10.jpg' ;
import wudc11 from '../assets/wudc11.jpg' ;
import wudc12 from '../assets/wudc12.jpg' ;
import wudc13 from '../assets/wudc13.jpg' ;
import wudc14 from '../assets/wudc14.jpg' ;
import wudc15 from '../assets/wudc15.jpg' ;
import wudc16 from '../assets/wudc16.jpg' ;
import wudc17 from '../assets/wudc17.jpg' ;
import wudc18 from '../assets/wudc18.jpg' ;
import wudc19 from '../assets/wudc19.jpg' ;
import wudc20 from '../assets/wudc20.jpg' ;
import wudc21 from '../assets/wudc21.jpg' ;
import wudc22 from '../assets/wudc22.jpg' ;
import wudc23 from '../assets/wudc23.jpg' ;
import wudc24 from '../assets/wudc24.jpg' ;
import wudc25 from '../assets/wudc25.jpg' ;
import wudc26 from '../assets/wudc26.jpg' ;
import wudc27 from '../assets/wudc27.jpg' ;
import wudc28 from '../assets/wudc28.jpg' ;
import wudc29 from '../assets/wudc29.jpg' ;
import wudc30 from '../assets/wudc30.jpg' ;
import wudc31 from '../assets/wudc31.jpg' ;
import wudc32 from '../assets/wudc32.jpg' ;
import wudc33 from '../assets/wudc33.jpg' ;
import wudc34 from '../assets/wudc34.jpg' ;
import wudc35 from '../assets/wudc35.jpg' ;
import wudc36 from '../assets/wudc36.jpg' ;
import wudc37 from '../assets/wudc37.jpg' ;
import wudc38 from '../assets/wudc38.jpg' ;
import wudc39 from '../assets/wudc39.jpg' ;
import wudc40 from '../assets/wudc40.jpg' ;
import wudc41 from '../assets/wudc41.jpg' ;
import wudc42 from '../assets/wudc42.jpg' ;
import wudc43 from '../assets/wudc43.jpg' ;
import wudc44 from '../assets/wudc44.jpg' ;
import wudc45 from '../assets/wudc45.jpg' ;
import wudc46 from '../assets/wudc46.jpg' ;
import wudc47 from '../assets/wudc47.jpg' ;
import wudc48 from '../assets/wudc48.jpg' ;
import wudc49 from '../assets/wudc49.jpg' ;
import wudc50 from '../assets/wudc50.jpg' ;
import wudc51 from '../assets/wudc51.jpg' ;
import wudc52 from '../assets/wudc52.jpg' ;
import wudc53 from '../assets/wudc53.jpg' ;
import wudc54 from '../assets/wudc54.jpg' ;
import wudc55 from '../assets/wudc55.jpg' ;
import wudc56 from '../assets/wudc56.jpg' ;
import wudc57 from '../assets/wudc57.jpg' ;
import wudc58 from '../assets/wudc58.jpg' ;
import wudc59 from '../assets/wudc59.jpg' ;
import wudc60 from '../assets/wudc60.jpg' ;
import wudc61 from '../assets/wudc61.jpg' ;
import wudc62 from '../assets/wudc62.jpg' ;
import wudc63 from '../assets/wudc63.jpg' ;
import wudc64 from '../assets/wudc64.jpg' ;
import wudc65 from '../assets/wudc65.jpg' ;
import wudc66 from '../assets/wudc66.jpg' ;
import wudc67 from '../assets/wudc67.jpg' ;
import schedule04 from '../assets/schedule04.jpg' ;
import schedule05 from '../assets/schedule05.jpg' ;
import schedule06 from '../assets/schedule06.jpg' ;
import schedule07 from '../assets/schedule07.jpg' ;



export default {
  schedule04,
  schedule05,
  schedule06,
  schedule07,
  shedule1,
  shedule2,
  shedule3,
  bg,
  debateGroup1,
  debateGroup2,
  debateGroup3,
  debateGroup4,
  debateGroup5,
  debateGroup6,
  debater1,
  debater2,
  debater3,
  debater4,
  debater5,
  debater6,
  debater7,
  debater8,
  debater9,
  debater10,
  debater11,
  logoBlack,
  logoWhite,
  ribbon,
  knife,
  logo,
  olympiaLogo,
  kyambogo,
  quote,
  sign,
  meal,
  justicebg,
  gold,
  bg1,
  award01,
  award02,
  award03,
  award04,
  shabil,
  ugandaDances,
  wudc01,
  wudc02,
  wudc03,
  wudc04,
  wudc05,
  wudc06,
  wudc07,
  wudc08,
  wudc09,
  wudc10,
  wudc11,
  wudc12,
  wudc13,
  wudc14,
  wudc15,
  wudc16,
  wudc17,
  wudc18,
  wudc19,
  wudc20,
  wudc21,
  wudc22,
  wudc23,
  wudc24,
  wudc25,
  wudc26,
  wudc27,
  wudc28,
  wudc29,
  wudc30,
  wudc31,
  wudc32,
  wudc33,
  wudc34,
  wudc35,
  wudc36,
  wudc37,
  wudc38,
  wudc39,
  wudc40,
  wudc41,
  wudc42,
  wudc43,
  wudc44,
  wudc45,
  wudc46,
  wudc47,
  wudc48,
  wudc49,
  wudc50,
  wudc51,
  wudc52,
  wudc53,
  wudc54,
  wudc55,
  wudc56,
  wudc57,
  wudc58,
  wudc59,
  wudc60,
  wudc61,
  wudc62,
  wudc63,
  wudc64,
  wudc65,
  wudc66,
  wudc67,
};


