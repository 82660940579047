import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus flex__center section__padding" id="about">

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.gold} alt="about_ribbon" className="ribbon__img" />
        <p className="p__opensans">The Olympia Debate Society is a premier organization dedicated to promoting the art of public speaking and debate. Established with the goal of fostering critical thinking and leadership skills, the Society has grown to become a respected leader in the world of competitive debating. With a strong commitment to excellence and a deep passion for the power of words, the Society provides a dynamic platform for members to develop their skills and engage with a diverse community of fellow debaters. Whether you are a seasoned veteran or a newcomer to the world of debate, the Olympia Debate Society offers a supportive and inclusive environment where you can hone your craft and connect with like-minded individuals. Join us and discover the transformative power of debate!.</p>
        {/*<button type="button" className="custom__button">Know More</button>*/}
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.gold} alt="about_ribbon" className="ribbon__img" />
        <p className="p__opensans">Olympia Debate Society has a rich history of fostering debate and public speaking skills among young people. Founded in 2019, the society has consistently provided opportunities for individuals to develop their critical thinking and argumentation skills in a supportive and inclusive environment. Over the years, the society has hosted numerous local and regional tournaments, helping to build a strong community of debaters in the area. In recent years, the society has expanded its reach to the national and even international level, establishing a reputation as one of the leading debate organizations in the world. Despite its growth and success, the society remains committed to its founding principles of inclusivity and excellence in debate.</p>
        {/*<button type="button" className="custom__button">Know More</button>*/}
      </div>
    </div>
  </div>
);

export default AboutUs;