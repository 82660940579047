import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding" id="PAUDC">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.shabil} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Founder's word" />
      <h1 className="headtext__cormorant">What we believe in</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans"> Dear Pan African Debating Community, </p>
        </div>
        <p className="p__opensans"> We are delighted to announce our bid to host the Pan African University Debate Championship 2024 in Uganda. This bid holds significant value for the Ugandan and greater East African Debating Community, as it aims to bridge the gap between established debate circuits in Africa and the East African Debate Community. With the already established efforts to look for more sponsors and the inclusion of youth in integration processes, we hope to bring a diverse group of participants from across East Africa and beyond . The Olympia Debate Society, with its active participation in various tournaments and a well-prepared local organizing committee, is confident in its ability to make this one of the most well-run debate championships. We believe that PAUDC should be a platform for exchanging knowledge and advising policy makers to better Africa. We hope to have your support in bringing the PAUDC member states to the Pearl of Africa.</p>
      </div>

      <div className="app__chef-sign">
        <p>Wabwiire Shabil Ojambo</p>
        <p className="p__opensans">President & Founder</p>
        <img src={images.sign} alt="sign_image" />
      </div>
    </div>
  </div>
);

export default Chef;
