import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">National ICT Innovation Hub</p>
        <p className="p__opensans">P.O.Box 74780 Clock Tower - Kampala Uganda. </p>
        <p className="p__opensans">info@olympiadebate.org </p>
        <p className="p__opensans">+256 758 459960</p>
        <p className="p__opensans">+256 786 680250</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.logoWhite} alt="footer_logo" />
        <p className="p__opensans">&quot;You don't win a debate by suppressing discussion; you win it with a better argument.&quot;</p>
        <img src={images.gold} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">10:00 am - 04:00 pm</p>
        <p className="p__opensans">Saturday-Sunday:</p>
        <p className="p__opensans">10:00 am - 02:00 pm</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 Olympia Debate Society. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;