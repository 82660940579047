import images from './images';

const government = [
  {
    title: 'Opening Government',
    price: '(OG)',
    tags: 'Prime Minister | Deputy Prime Minister',
  },
  {
    title: 'Closing Government',
    price: '(CG)',
    tags: 'Member of Government | Government Whip',
  },
];

const opposition = [
  {
    title: 'Opening Opposition',
    price: '(OO)',
    tags: 'Leader | Deputy Leader of Opposition',
  },
  {
    title: "Closing Opposition",
    price: '(CO)',
    tags: 'Member of Opposition | Opposition Whip',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Njuguna Macharia',
    subtitle: 'Chief Adjudicator',
  },
  {
    imgUrl: images.award03,
    title: 'Wesoamo Zempare',
    subtitle: 'Co-Chief Adjudicator',
  },
  {
    imgUrl: images.award04,
    title: 'Maison Peiterson',
    subtitle: 'Co-Chief Adjudicator',
  },
];

export default { government, opposition, awards };
