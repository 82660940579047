import React from "react";
import "./PhotoFlipperContainer.css";
//import { PhotoFlipper } from '../../components';
import { images } from '../../constants';
import PhotoFlipper from "../../components/PhotoFlipper/PhotoFlipper";

const imagesQ = [

  images.schedule04,
  images.schedule05,
  images.schedule06,
  images.schedule07,
  images.shedule1,
  images.shedule2,
  images.shedule3,
];


const PhotoFlipperContainer = () => {
  return (
    <div className="photo-flipper-container" id="olympiaIV">
      <h3 className="headtext__cormorant">Olympia IV 2023</h3>
      <PhotoFlipper images={imagesQ} />
    </div>
  );
};

export default PhotoFlipperContainer;


/*
const imagesQ = [

  images.debateGroup1,
  images.debateGroup2,
  images.debateGroup3,
  images.debateGroup4,
];

function PhotoFlipper() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  function handleScroll(event) {
    if (event.deltaY < 0 && currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else if (event.deltaY > 0 && currentImageIndex < imagesQ.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  }

  return (
    <div className="photo-flipper-container">
      <div className="photo-flipper" onWheel={handleScroll}>
        <div className="photo-container">
          {imagesQ.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              className={`photo ${index === currentImageIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <div className="navigation-handle">
          {imagesQ.map((image, index) => (
            <span
              key={index}
              className={`dot ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => setCurrentImageIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PhotoFlipper;
*/
/* --------working test 1
const images = [

  images.debateGroup1,
  images.debateGroup2,
  images.debateGroup3,
  images.debateGroup4,
];

function PhotoFlipper() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentImageIndex]);

  return (
    <div className="photo-flipper">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Image ${index + 1}`}
          className={`photo ${index === currentImageIndex ? 'active' : ''}`}
        />
      ))}
    </div>
  );
}


export default PhotoFlipper;

--------working test 1*/