// import React from 'react';

// import SubHeading from '../SubHeading/SubHeading';
// import './Newsletter.css';

// const Newsletter = () => (
//   <div className="app__newsletter">
//     <div className="app__newsletter-heading">
//       <SubHeading title="Newsletter" />
//       <h1 className="headtext__cormorant">Subscribe To Our Newsletter</h1>
//       <p className="p__opensans">And never miss latest Updates!</p>
//     </div>
//     <div className="app__newsletter-input flex__center">
//       <input type="email" placeholder="Enter your email address" />
//       <button type="button" className="custom__button">Subscribe</button>
//     </div>
//   </div>
// );

// export default Newsletter;

import React, { useState } from 'react';

import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';

const Newsletter = () => {
  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: '',
    organization: '',
    message: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Basic client-side validation
    if (!values.name || !values.email) {
      // Display an error message to the user and return early
      console.error('Name and email are required.');
      // You can set a state to show an error message on the UI
      return;
    }

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbwJqG1fVHBo_qLzkOtQE25Q_zRHkT0-IyUkZrjgU-X0JwaryBF_7_sZ6l3gVFLUCfAuRw/exec', // Replace with your Google Apps Script web app URL   AKfycbwJqG1fVHBo_qLzkOtQE25Q_zRHkT0-IyUkZrjgU-X0JwaryBF_7_sZ6l3gVFLUCfAuRw
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'no-cors',
          body: JSON.stringify(values),
        }
      );

      // Assume success unless an error occurs
      console.log('Form submission successful');
      // Optionally, reset the form fields or show a success message to the user.
      setValues({
        name: '',
        phone: '',
        email: '',
        organization: '',
        message: '',
      });

    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div className="app__newsletter">
      <div className="app__newsletter-heading">
        <SubHeading title="Newsletter" />
        <h1 className="headtext__cormorant">Subscribe To Our Newsletter</h1>
        <p className="p__opensans">And never miss latest Updates!</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="app__newsletter-input flex__center">
          <input
            type="text"
            placeholder="Enter your name"
            value={values.name}
            onChange={handleChange('name')}
          />
          <input
            type="tel"
            placeholder="Enter your phone number"
            value={values.phone}
            onChange={handleChange('phone')}
          />
          <input
            type="email"
            placeholder="Enter your email address"
            value={values.email}
            onChange={handleChange('email')}
          />
          <input
            type="text"
            placeholder="Enter your organization"
            value={values.organization}
            onChange={handleChange('organization')}
          />
          <input
            placeholder="Enter your message"
            value={values.message}
            onChange={handleChange('message')}
          />
          <button type="submit" className="custom__button">
            Subscribe
          </button>
        </div>
      </form>
    </div>
  );
};

export default Newsletter;
