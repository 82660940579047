import React, { useState } from 'react'

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { Document, Page } from 'react-pdf';

import './Budget.css'

import pdf from '../../assets/PansBudget.pdf'

import {Outline, pdfjs, Page as ReactPdfPage} from 'react-pdf';
import HTMLFlipBook from "react-pageflip";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
//   ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


function Budget() {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }){
    setNumPages(numPages);
  }

  const width = 300;
  const height = 424;

  const Page = React.forwardRef(({ pageNumber }, ref) => {
    return (
      <div ref={ref}>
        <ReactPdfPage pageNumber={pageNumber} width={width} height={height} />
      </div>
    );
  });


  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  function onPage (e)  {
    console.log(e)
  }

  return (
    <div className="Budget" id="paudc">
    <div>
      <h3 className="headtext__cormorant">PAUDC 2024 BUDGET</h3>
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} >
          <HTMLFlipBook
            width={width}
            height={height}
            size="fixed"
            minWidth={width}
            maxWidth={width}
            minHeight={height}
            maxHeight={height}
            showPageCorners={false}
            maxShadowOpacity={1}
            onFlip={onPage}
          >
            {Array.from(
              new Array(numPages),
              (el, index) => (
                <Page
                  devicePixelRatio={window.devicePixelRatio}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  number={index + 1}
                />
              ),
            )}
          </HTMLFlipBook>

      </Document>
    </div>
    </div>
  );
}

export default Budget