import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    
    <div className="app__wrapper_info">
      <SubHeading title="Chase The Dream" />
      <h1 className="app__header-h1">The Debater's Dream</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}> Join the Pursuit of Excellence: Be a Part of the Olympia Debate Society! Fuel your passion for words and wit and chase your debater's dream with us. From sharpening your critical thinking skills to refining your public speaking abilities, we've got you covered. With our top-notch training and opportunities to participate in local, regional, and even international tournaments, you'll be on your way to becoming a seasoned debater. So come, let's raise the bar and make a difference through the power of debate. Join us in our pursuit of excellence.</p>
      {/*<button type="button" className="custom__button" id='about'>Explore Olympia</button>*/}
    </div>

    <div className="app__wrapper_img">
      <img src={images.debateGroup6} alt="header_img" />
    </div>
  </div>
);

export default Header;

