
import React, { useState } from "react";
import "./PhotoFlipper.css";

const PhotoFlipper = ({ images }) => {


  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  return (
    <div className="photo-flipper">
      <button onClick={handlePrevImage}>{"<"}</button>
      <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} />
      <button onClick={handleNextImage}>{">"}</button>
    </div>
  );
};

export default PhotoFlipper;